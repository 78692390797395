// Extend the Window interface to include the currentUser property
declare global {
  interface Window {
    currentUser: {
      role: string;
    };
  }
}

import { Controller } from "@hotwired/stimulus";
import moment from "moment";

export default class extends Controller {
  static targets = [
    "city",
    "locationType",
    "building",
    "flughafenZone",
    "area",
    "finger",
    "level",
    "address",
    "playerName",
    "boxId",
    "aptCode",
    "country",
    "gfCategories",
  ];

  declare cityTarget: HTMLSelectElement;
  declare locationTypeTarget: HTMLSelectElement;
  declare buildingTarget: HTMLSelectElement;
  declare flughafenZoneTarget: HTMLSelectElement;
  declare areaTarget: HTMLSelectElement;
  declare fingerTarget: HTMLSelectElement;
  declare levelTarget: HTMLSelectElement;
  declare addressTarget: HTMLInputElement;
  declare playerNameTarget: HTMLInputElement;
  declare boxIdTarget: HTMLInputElement;
  declare aptCodeTarget: HTMLInputElement;
  declare countryTarget: HTMLSelectElement;
  declare gfCategoriesTarget: HTMLSelectElement;

  declare hasCityTarget: boolean;
  declare hasLocationTypeTarget: boolean;
  declare hasBuildingTarget: boolean;
  declare hasFlughafenZoneTarget: boolean;
  declare hasAreaTarget: boolean;
  declare hasFingerTarget: boolean;
  declare hasLevelTarget: boolean;
  declare hasAddressTarget: boolean;
  declare hasPlayerNameTarget: boolean;
  declare hasBoxIdTarget: boolean;
  declare hasAptCodeTarget: boolean;
  declare hasCountryTarget: boolean;
  declare hasGfCategoriesTarget: boolean;

  connect() {
    console.log("Daisy Dashboard connected");
    this.addRowClickListeners();

    this.attachSortingListeners();

    if (this.hasCityTarget) {
      this.cityTarget.addEventListener("change", this.filter_change.bind(this));
    } else {
      console.error("city target not found");
    }

    if (this.hasLocationTypeTarget) {
      this.locationTypeTarget.addEventListener(
        "input",
        this.filter_change.bind(this)
      );
    } else {
      console.error("locationType target not found");
    }

    if (this.hasBuildingTarget) {
      this.buildingTarget.addEventListener(
        "change",
        this.filter_change.bind(this)
      );
    } else {
      console.error("building target not found");
    }

    if (this.hasFlughafenZoneTarget) {
      this.flughafenZoneTarget.addEventListener(
        "change",
        this.filter_change.bind(this)
      );
    } else {
      console.error("flughafenZone target not found");
    }

    if (this.hasAreaTarget) {
      this.areaTarget.addEventListener("change", this.filter_change.bind(this));
    } else {
      console.error("area target not found");
    }

    if (this.hasFingerTarget) {
      this.fingerTarget.addEventListener(
        "change",
        this.filter_change.bind(this)
      );
    } else {
      console.error("finger target not found");
    }

    if (this.hasLevelTarget) {
      this.levelTarget.addEventListener(
        "change",
        this.filter_change.bind(this)
      );
    } else {
      console.error("level target not found");
    }

    if (this.hasAddressTarget) {
      this.addressTarget.addEventListener(
        "input",
        this.filter_change.bind(this)
      );
    } else {
      console.error("address target not found");
    }

    if (this.hasPlayerNameTarget) {
      this.playerNameTarget.addEventListener(
        "input",
        this.filter_change.bind(this)
      );
    } else {
      console.error("playerName target not found");
    }

    if (this.hasBoxIdTarget) {
      this.boxIdTarget.addEventListener("input", this.filter_change.bind(this));
    } else {
      console.error("boxId target not found");
    }

    if (this.hasAptCodeTarget) {
      this.aptCodeTarget.addEventListener(
        "input",
        this.filter_change.bind(this)
      );
    } else {
      console.error("aptCode target not found");
    }

    if (this.hasCountryTarget) {
      this.countryTarget.addEventListener(
        "change",
        this.filter_change.bind(this)
      );
    } else {
      console.error("country target not found");
    }

    if (this.hasGfCategoriesTarget) {
      this.gfCategoriesTarget.addEventListener(
        "change",
        this.filter_change.bind(this)
      );
    } else {
      console.error("gfCategories target not found");
    }
  }

  sortTable(columnIndex: number, direction: string) {
    const table = this.element.querySelector("table");
    if (!table) return;

    const rows = Array.from(table.querySelectorAll("tbody tr:not(.hidden)"));

    rows.sort((rowA, rowB) => {
      const cellA =
        (rowA as HTMLTableRowElement).cells[columnIndex]?.textContent?.trim() ||
        "";
      const cellB =
        (rowB as HTMLTableRowElement).cells[columnIndex]?.textContent?.trim() ||
        "";

      if (direction === "asc") {
        return cellA.localeCompare(cellB, undefined, { numeric: true });
      } else {
        return cellB.localeCompare(cellA, undefined, { numeric: true });
      }
    });

    const tbody = table.querySelector("tbody");
    if (tbody) {
      rows.forEach((row) => tbody.appendChild(row));
    }

    this.updateRowStyles();
  }

  attachSortingListeners() {
    const table = this.element.querySelector("table");
    if (!table) return;

    const sortIcons = Array.from(table.querySelectorAll(".glyphicon"));

    sortIcons.forEach((icon) => {
      icon.addEventListener("click", (event) => {
        event.stopPropagation();

        const header = icon.closest("th");
        if (!header) return;

        const columnIndex = Array.from(header.parentElement!.children).indexOf(
          header
        );
        const currentDirection = header.dataset.sortDirection || "asc";
        const newDirection = currentDirection === "asc" ? "desc" : "asc";

        header.dataset.sortDirection = newDirection;

        this.updateSortIcons(header, newDirection);
        this.sortTable(columnIndex, newDirection);
      });
    });
  }

  updateSortIcons(header: Element, direction: string) {
    const allHeaders = header.parentElement?.children;
    if (allHeaders) {
      Array.from(allHeaders).forEach((th) => {
        const upIcon = th.querySelector(".glyphicon-arrow-up");
        const downIcon = th.querySelector(".glyphicon-arrow-down");
        if (upIcon) upIcon.classList.remove("active-sort");
        if (downIcon) downIcon.classList.remove("active-sort");
      });
    }

    const upIcon = header.querySelector(".glyphicon-arrow-up");
    const downIcon = header.querySelector(".glyphicon-arrow-down");

    if (direction === "asc" && upIcon) {
      upIcon.classList.add("active-sort");
      if (downIcon) downIcon.classList.remove("active-sort");
    } else if (direction === "desc" && downIcon) {
      downIcon.classList.add("active-sort");
      if (upIcon) upIcon.classList.remove("active-sort");
    }
  }

  filter_change() {
    var selectedCity = "";
    var selectedLocationType = "";
    var selectedBuilding = "";
    var selectedFlughafenZone = "";
    var selectedArea = "";
    var selectedFinger = "";
    var selectedLevel = "";
    var selectedAddress = "";
    var selectedPlayerName = "";
    var selectedBoxId = "";
    var selectedAptCode = "";
    var selectedCountry = "";
    var selectedGfCategories = "";

    if (this.hasCityTarget) {
      selectedCity = this.cityTarget?.value || "";
    } else {
      selectedCity;
    }

    if (this.hasLocationTypeTarget) {
      selectedLocationType = this.locationTypeTarget?.value || "";
    } else {
      selectedLocationType;
    }

    if (this.hasBuildingTarget) {
      selectedBuilding = this.buildingTarget?.value || "";
    } else {
      selectedBuilding;
    }

    if (this.hasFlughafenZoneTarget) {
      selectedFlughafenZone = this.flughafenZoneTarget?.value || "";
    } else {
      selectedFlughafenZone;
    }

    if (this.hasAreaTarget) {
      selectedArea = this.areaTarget?.value || "";
    } else {
      selectedArea;
    }

    if (this.hasFingerTarget) {
      selectedFinger = this.fingerTarget?.value || "";
    } else {
      selectedFinger;
    }

    if (this.hasLevelTarget) {
      selectedLevel = this.levelTarget?.value || "";
    } else {
      selectedLevel;
    }

    if (this.hasAddressTarget) {
      selectedAddress = this.addressTarget?.value || "";
    } else {
      selectedAddress;
    }

    if (this.hasPlayerNameTarget) {
      selectedPlayerName = this.playerNameTarget?.value || "";
    } else {
      selectedPlayerName;
    }

    if (this.hasBoxIdTarget) {
      selectedBoxId = this.boxIdTarget?.value || "";
    } else {
      selectedBoxId;
    }

    if (this.hasAptCodeTarget) {
      selectedAptCode = this.aptCodeTarget?.value || "";
    } else {
      selectedAptCode;
    }

    if (this.hasCountryTarget) {
      selectedCountry = this.countryTarget?.value || "";
    } else {
      selectedCountry;
    }

    if (this.hasGfCategoriesTarget) {
      selectedGfCategories =
        this.gfCategoriesTarget?.value.trim().toLowerCase() || "";
    } else {
      selectedGfCategories;
    }

    this.updateRowsWithFilters(
      selectedCity,
      selectedLocationType,
      selectedBuilding,
      selectedFlughafenZone,
      selectedArea,
      selectedFinger,
      selectedLevel,
      selectedAddress,
      selectedPlayerName,
      selectedBoxId,
      selectedAptCode,
      selectedCountry,
      selectedGfCategories
    );
  }

  updateRowsWithFilters(
    city,
    locationType,
    building,
    flughafenZone,
    area,
    finger,
    level,
    address,
    playerName,
    boxId,
    aptCode,
    country,
    gfCategories
  ) {
    const rows = this.element.querySelectorAll("tbody tr");

    const cityRegex = city ? new RegExp(city, "i") : null;
    const buildingRegex = building ? new RegExp(building, "i") : null;
    const flughafenZoneRegex = flughafenZone
      ? new RegExp(flughafenZone, "i")
      : null;
    const areaRegex = area ? new RegExp(area, "i") : null;
    const fingerRegex = finger ? new RegExp(finger, "i") : null;
    const levelRegex = level ? new RegExp(level, "i") : null;
    const addressRegex = address ? new RegExp(address, "i") : null;
    const playerNameRegex = playerName ? new RegExp(playerName, "i") : null;
    const boxIdRegex = boxId ? new RegExp(boxId, "i") : null;
    const countryRegex = country ? new RegExp(country, "i") : null;

    rows.forEach((row) => {
      const rowCity = row.getAttribute("data-city") || "";
      const rowLocationType = row.getAttribute("data-location-type");
      const rowBuilding = row.getAttribute("data-building") || "";
      const rowFlughafenZone = row.getAttribute("data-flughafen-zone") || "";
      const rowArea = row.getAttribute("data-area") || "";
      const rowFinger = row.getAttribute("data-finger") || "";
      const rowLevel = row.getAttribute("data-level") || "";
      const rowAddress = row.getAttribute("data-address") || "";
      const rowPlayerName = row.getAttribute("data-player-name") || "";
      const rowBoxId = row.getAttribute("data-box-id") || "";
      const rowAptCode = row.getAttribute("data-apt-code");
      const rowCountry = row.getAttribute("data-country") || "";
      const rowCategories = (row as HTMLElement).dataset.category || "";
      let rowCategoriesArray = rowCategories
        .split(",")
        .map((cat) => cat.trim().toLowerCase());

      const gfCategoriesMatches =
        !gfCategories || rowCategoriesArray.includes(gfCategories);

      const cityMatches = cityRegex ? cityRegex.test(rowCity) : true;

      const locTypeMatches = !locationType || locationType === rowLocationType;

      const buildingMatches = buildingRegex
        ? buildingRegex.test(rowBuilding)
        : true;

      const flughafenZoneMatches = flughafenZoneRegex
        ? flughafenZoneRegex.test(rowFlughafenZone)
        : true;

      const areaMatches = areaRegex ? areaRegex.test(rowArea) : true;

      const fingerMatches = fingerRegex ? fingerRegex.test(rowFinger) : true;

      const levelMatches = levelRegex ? levelRegex.test(rowLevel) : true;

      const addressMatches = addressRegex
        ? addressRegex.test(rowAddress)
        : true;

      const playerNameMatches = playerNameRegex
        ? playerNameRegex.test(rowPlayerName)
        : true;

      const boxIdMatches = boxIdRegex ? boxIdRegex.test(rowBoxId) : true;

      const aptCodeMatches =
        !aptCode || aptCode === "" || rowAptCode === aptCode;

      const countryMatches = countryRegex
        ? countryRegex.test(rowCountry)
        : true;

      if (
        cityMatches &&
        locTypeMatches &&
        buildingMatches &&
        flughafenZoneMatches &&
        areaMatches &&
        fingerMatches &&
        levelMatches &&
        addressMatches &&
        playerNameMatches &&
        boxIdMatches &&
        aptCodeMatches &&
        countryMatches &&
        gfCategoriesMatches
      ) {
        row.classList.remove("hidden");
      } else {
        row.classList.add("hidden");
      }
    });

    this.hideEmptyColumns();

    this.updateRowStyles();
  }

  updateRowStyles() {
    const rows = this.element.querySelectorAll("tbody tr:not(.hidden)");
    rows.forEach((row, index) => {
      if (index % 2 === 0) {
        row.classList.add("even");
        row.classList.remove("odd");
      } else {
        row.classList.add("odd");
        row.classList.remove("even");
      }
    });
  }

  hideEmptyColumns() {
    const table = this.element.querySelector("table");
    if (!table) return;

    const rows = Array.from(table.querySelectorAll("tbody tr:not(.hidden)"));
    const headers = Array.from(table.querySelectorAll("thead th"));

    let allColumnsEmpty = true;

    headers.forEach((header, colIndex) => {
      let isEmpty = true;

      rows.forEach((row) => {
        const cell = (row as HTMLTableRowElement).cells[colIndex];
        if (cell && cell.textContent && cell.textContent.trim() !== "") {
          isEmpty = false;
          allColumnsEmpty = false;
        }
      });

      if (isEmpty && !allColumnsEmpty) {
        header.classList.add("hidden");
        rows.forEach((row) => {
          const cell = (row as HTMLTableRowElement).cells[colIndex];
          if (cell) {
            cell.classList.add("hidden");
          }
        });
      } else if (!isEmpty && !allColumnsEmpty) {
        header.classList.remove("hidden");
        rows.forEach((row) => {
          const cell = (row as HTMLTableRowElement).cells[colIndex];
          if (cell) {
            cell.classList.remove("hidden");
          }
        });
      }

      if (allColumnsEmpty) {
        headers.forEach((header) => header.classList.remove("hidden"));
      }
    });
  }

  addRowClickListeners() {
    const rows = this.element.querySelectorAll("tr[data-show-url]");
    rows.forEach((row) => {
      row.addEventListener("click", (event) => {
        const url = row.getAttribute("data-show-url");
        if (url) {
          window.location.href = url;
        }
      });
    });
  }

  getFilteredIds(): string[] {
    const table = document.querySelector("table");
    if (!table) return [];

    const rows = Array.from(table.querySelectorAll("tbody tr:not(.hidden)"));
    const recordIds = rows.map((row) => (row as HTMLElement).dataset.boxId);
    return recordIds.filter(
      (id) => id !== undefined && id !== null
    ) as string[];
  }

  collectFilteredIds(event) {
    event.preventDefault();

    const filteredIds = this.getFilteredIds();
    const url = new URL("/schema_contents/excel", window.location.origin);

    if (filteredIds.length > 0) {
      url.searchParams.append("filtered_ids", filteredIds.join(","));
    }

    window.location.href = url.toString();
  }
}
