import { Turbo } from "@hotwired/turbo-rails";
import { FetchRequest } from "@rails/request.js";

function whitespace_undescore(str) {
  return str ? str.split(/[\s_]+/) : [];
}

// constructs the suggestion engine
var spots = new Bloodhound({
  datumTokenizer: whitespace_undescore,
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  // `states` is an array of state names defined in "The Basics"
  local: [],
});

var global_clipboard;
var global_clipboard_type;

document.addEventListener("turbo:before-visit", (event) => {
  console.log("before-visit:");
  console.log(event);
});

$(document).on("turbo:render", () => {
  $(".selectpicker").selectpicker();

  $("#edit_spot").on("change", ".selectpicker.video", update_preview);
  $("#edit_spot").on("change", ".selectpicker.image", update_preview);
  $("#edit_spot").on("change", ".selectpicker.welcome", update_preview);

  $("#new_spot").on("change", ".selectpicker.video", update_preview);
  $("#new_spot").on("change", ".selectpicker.image", update_preview);
  $("#new_spot").on("change", ".selectpicker.welcome", update_preview);
});

$(document).on("turbo:load", () => {
  if (typeof spot_names !== "undefined" && spots) {
    spots.add(spot_names);
  }

  var spot_ids_to_destroy = [];

  $("#spots .loading").css({
    cursor: "wait",
    display: "none",
    height: "100%",
    width: "100%",
    top: 0,
    position: "absolute",
  });
  $("#spots .loading").appendTo("#spots");

  $("#spot-search").typeahead(
    {
      hint: false,
      highlight: true,
      minLength: 1,
    },
    {
      name: "spots",
      source: spots,
    }
  );

  $("#spot-overview-search-from").submit(function (event) {
    event.preventDefault();

    var spot_name = $("#spot-search").val();
    var type = spot_types[spot_name];
    if (type) {
      window.location = window.location + "/" + type;
    }
    return false;
  });

  $("#cancel-paste-button").hide();

  $("#spots").on("click", "#cancel-paste-button", function (e) {
    $("table").addClass("copy");
    $("table").removeClass("paste");
    $("table").removeClass("text");
    $("table").removeClass("image");
    $("table").removeClass("video");
    $("table").removeClass("date");
    $(this).hide();

    e.preventDefault();
  });

  $("#spots").on("change", "input.destroy", function () {
    if (this.checked) {
      spot_ids_to_destroy.push($(this).val());
    } else {
      spot_ids_to_destroy.splice(
        $.inArray($(this).val(), spot_ids_to_destroy),
        1
      );
    }
  });

  $("#spot_price_region_filter").change(function (event) {
    filter_table();
  });

  $("#spot_category_filter").change(function (event) {
    filter_table();
  });

  $("#spot_valid_from_filter").change(function (event) {
    filter_table();
  });

  $("#spot_valid_to_filter").change(function (event) {
    filter_table();
  });

  $("#spots").on("click", ".preview_link", function (event) {
    var url = $(this).attr("href");

    window.open(
      url,
      "preview",
      "toolbar=no,scrollbars=no,top=500,left=500,width=290,height=520"
    );

    event.preventDefault();
  });

  $("#htmlspot").on("click", ".preview_link", function (event) {
    console.log("preview link clicked");
    var url = $(this).attr("href");

    window.open(
      url,
      "preview",
      "toolbar=no,scrollbars=no,top=500,left=500,width=290,height=520"
    );

    event.preventDefault();
  });

  $("#spots").on("click", ".copy-overlay", function (event) {
    var type = $(this).data("type");
    var value = $(this).data("value").toString();

    copy_value(type, value);

    event.preventDefault();
  });

  $("#spots").on("click", ".paste-overlay", function (event) {
    var url = $(this).data("url");
    var attribute = $(this).data("attribute");

    paste_value(global_clipboard, url, attribute);

    event.preventDefault();
  });

  $("#spots").on("click", ".col-copy", function (event) {
    var url = $(this).data("url");
    var attribute = $(this).data("attribute");

    paste_col_value(global_clipboard, url, attribute);

    event.preventDefault();
  });
});

function update_preview(e) {
  e.preventDefault();
  var select = $(this);
  var name = $(this).data("attribute");

  if ($(select).val() == "" || $(select).val() == null) {
    $("#" + name + "_preview").attr(
      "src",
      "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
    );
  } else {
    $("#" + name + "_preview").attr("src", attachments[$(select).val()]);
  }
}

function copy_value(type, value) {
  global_clipboard = value;
  global_clipboard_type = type;
  $("table").removeClass("copy");
  $("table").addClass("paste");
  $("table").addClass(type);

  $("#cancel-paste-button").show();
}

function paste_value(value, url, attribute) {
  console.log("saving " + value + " for attribute " + attribute + " at " + url);

  var payloadData = {};
  payloadData[attribute] = value;

  const request = new FetchRequest("patch", url, {
    body: JSON.stringify({ spot: payloadData }),
    responseKind: "turbo-stream",
  });
  request.perform().catch((error) => {
    alert(error);
  });
}

function paste_col_value(value, url, attribute) {
  console.log("saving " + value + " for attribute " + attribute + " at " + url);

  var visibleIDs = $("tr.editable")
    .filter(":visible")
    .map(function () {
      return $(this).data("id");
    })
    .get();

  var payloadData = {};
  payloadData[attribute] = value;

  const request = new FetchRequest("patch", url, {
    body: JSON.stringify({ spot: payloadData, ids: visibleIDs.join(",") }),
    responseKind: "turbo-stream",
  });
  request.perform().catch((error) => {
    alert(error);
  });
}

function filter_table() {
  var price_region = $("#spot_price_region_filter").val();
  var category = $("#spot_category_filter").val();
  var valid_from = $("#spot_valid_from_filter").val();
  var valid_to = $("#spot_valid_to_filter").val();

  $("tr.editable").each(function (index) {
    var price_region_show =
      price_region == "" || $(this).data("price-region") == price_region;
    var category_show = category == "" || $(this).data("category") == category;
    var valid_from_show =
      valid_from == "" || $(this).data("valid-from") == valid_from;
    var valid_to_show = valid_to == "" || $(this).data("valid-to") == valid_to;
    if (
      price_region_show &&
      category_show &&
      valid_from_show &&
      valid_to_show
    ) {
      $(this).show();
    } else {
      $(this).hide();
    }
  });
}
