// Extend the Window interface to include the currentUser property
declare global {
  interface Window {
    currentUser: {
      role: string;
    };
  }
}

import { Controller } from "@hotwired/stimulus";
import moment from "moment";

export default class extends Controller {
  static targets = [
    "checkbox",
    "aptCode",
    "locCategory",
    "scenario",
    "description",
    "boxId",
    "tickerText",
  ];

  declare checkboxTargets: HTMLInputElement[];
  declare aptCodeTarget: HTMLSelectElement;
  declare locCategoryTarget: HTMLSelectElement;
  declare scenarioTarget: HTMLSelectElement;
  declare descriptionTarget: HTMLInputElement;
  declare boxIdTarget: HTMLInputElement;
  declare tickerTextTarget: HTMLInputElement;

  declare hasAptCodeTarget: boolean;
  declare hasLocCategoryTarget: boolean;
  declare hasScenarioTarget: boolean;
  declare hasDescriptionTarget: boolean;
  declare hasBoxIdTarget: boolean;
  declare hasTickerTextTarget: boolean;

  connect() {
    console.log("Szenario Dashboard connected");

    this.loadCheckboxState(); // Load checkbox state from localStorage

    this.updateCheckAll(); // Initialize the state of the header checkbox
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.addEventListener("change", this.toggle.bind(this));
    });
    if (this.hasAptCodeTarget) {
      this.aptCodeTarget.addEventListener(
        "change",
        this.filter_change.bind(this)
      );
    } else {
      console.error("aptCode target not found");
    }

    if (this.hasLocCategoryTarget) {
      this.locCategoryTarget.addEventListener(
        "change",
        this.filter_change.bind(this)
      );
    } else {
      console.error("locCategory target not found");
    }

    if (this.hasScenarioTarget) {
      this.scenarioTarget.addEventListener(
        "change",
        this.filter_change.bind(this)
      );
    } else {
      console.error("scenario target not found");
    }

    // Attach event listeners for the search fields
    if (this.hasDescriptionTarget) {
      this.descriptionTarget?.addEventListener(
        "input",
        this.filter_change.bind(this)
      );
    } else {
      console.error("description target not found");
    }

    if (this.hasBoxIdTarget) {
      this.boxIdTarget?.addEventListener(
        "input",
        this.filter_change.bind(this)
      );
    } else {
      console.error("boxId target not found");
    }

    if (this.hasTickerTextTarget) {
      this.tickerTextTarget?.addEventListener(
        "input",
        this.filter_change.bind(this)
      );
    } else {
      console.error("tickerText target not found");
    }

    const checkAllCheckbox = this.element.querySelector(
      'input[name$="[check_all]"]'
    ) as HTMLInputElement;
    if (checkAllCheckbox) {
      checkAllCheckbox.addEventListener("change", this.change.bind(this));
    }
  }

  change(event) {
    const checkAllCheckbox = event.target as HTMLInputElement;
    const checked = checkAllCheckbox.checked;
    const visibleCheckboxes = this.visibleCheckboxes();

    visibleCheckboxes.forEach((checkbox) => {
      checkbox.checked = checked;
    });

    this.updateCheckAll();
    this.saveCheckboxState();
  }

  toggle(event) {
    this.updateCheckAll();
    this.saveCheckboxState();

    const checkbox = event.target as HTMLInputElement;

    const row = checkbox.closest("tr");
    if (row) {
      const aptCode = row.getAttribute("data-apt-code");
      const boxId = row.getAttribute("data-box-id");

      if ((aptCode === "MUC" && boxId === "DSS") || aptCode !== "MUC") {
        return;
      } else {
        if (checkbox.checked) {
          const matchingRow = document.querySelector(
            `tr[data-apt-code="MUC"][data-box-id="DSS"]`
          ) as HTMLElement;

          if (matchingRow) {
            const matchingCheckbox = matchingRow.querySelector(
              ".scenario-checkbox"
            ) as HTMLInputElement;

            if (matchingCheckbox && !matchingCheckbox.checked) {
              matchingCheckbox.checked = true;
            }
          }
        }
      }
    }
  }

  updateCheckAll() {
    const totalSelectedCheckboxes = this.checkboxTargets.filter(
      (checkbox) => checkbox.checked
    ).length;

    const visibleCheckboxes = this.visibleCheckboxes();
    const allChecked = visibleCheckboxes.every((checkbox) => checkbox.checked);
    const checkAllCheckbox = this.element.querySelector(
      'input[name$="[check_all]"]'
    ) as HTMLInputElement;

    if (checkAllCheckbox) {
      checkAllCheckbox.checked = allChecked;
    }

    const assignScenarioButton = document.getElementById(
      "assign-scenario"
    ) as HTMLButtonElement | null;

    if (assignScenarioButton) {
      if (totalSelectedCheckboxes > 0) {
        assignScenarioButton.disabled = false;
        assignScenarioButton.textContent = `Szenario bearbeiten ${totalSelectedCheckboxes} Stele(n)`;
      } else {
        assignScenarioButton.disabled = true;
        assignScenarioButton.textContent = "Assign Szenario";
      }
    }

    const resetToStandardButton = document.getElementById(
      "reset-standard"
    ) as HTMLButtonElement | null;

    if (resetToStandardButton) {
      if (totalSelectedCheckboxes > 0) {
        resetToStandardButton.disabled = false;
        resetToStandardButton.textContent = `Szenario für ${totalSelectedCheckboxes} Stele(n) auf Standard zurücksetzen`;
      } else {
        resetToStandardButton.disabled = true;
        resetToStandardButton.textContent = "Auf Standard zurücksetzen";
      }
    }

    const playerSpotListButton = document.getElementById(
      "player-spots"
    ) as HTMLButtonElement | null;

    if (playerSpotListButton) {
      if (totalSelectedCheckboxes > 0) {
        playerSpotListButton.disabled = false;
        playerSpotListButton.textContent = `Spotliste für ${totalSelectedCheckboxes} Stele(n) anzeigen`;
      } else {
        playerSpotListButton.disabled = true;
        playerSpotListButton.textContent = "Spotliste anzeigen";
      }
    }

    const number_of_visible_players = visibleCheckboxes.length;

    const visiblePlayersCountElement = document.getElementById(
      "visible_players"
    ) as HTMLSpanElement | null;

    if (visiblePlayersCountElement) {
      visiblePlayersCountElement.textContent = `${number_of_visible_players} Stele(n) angezeigt`;
    }

    const selectedPlayersCountElement = document.getElementById(
      "selected_players"
    ) as HTMLSpanElement | null;

    if (selectedPlayersCountElement) {
      selectedPlayersCountElement.textContent = `${totalSelectedCheckboxes} Stele(n) ausgewählt`;
    }
  }

  // Get the checkboxes visible after filtering
  visibleCheckboxes() {
    return this.checkboxTargets.filter(
      (checkbox) => !checkbox.closest("tr")?.classList.contains("hidden")
    );
  }

  filter_change() {
    var selectedAptCode = "";
    var selectedLocCategory = "";
    var selectedScenario = "";
    var selectedDescription = "";
    var selectedBoxId = "";
    var selectedTickerText = "";

    if (this.hasAptCodeTarget) {
      selectedAptCode = this.aptCodeTarget?.value || "";
    } else {
      selectedAptCode;
    }

    if (this.hasLocCategoryTarget) {
      selectedLocCategory = this.locCategoryTarget?.value || "";
    } else {
      selectedLocCategory;
    }

    if (this.hasScenarioTarget) {
      selectedScenario = this.scenarioTarget?.value || "";
    } else {
      selectedScenario;
    }

    if (this.hasDescriptionTarget) {
      selectedDescription = this.descriptionTarget?.value || "";
    } else {
      selectedDescription;
    }

    if (this.hasBoxIdTarget) {
      selectedBoxId = this.boxIdTarget?.value || "";
    } else {
      selectedBoxId;
    }

    if (this.hasTickerTextTarget) {
      selectedTickerText = this.tickerTextTarget?.value || "";
    } else {
      selectedTickerText;
    }

    this.updateRowsWithFilters(
      selectedAptCode,
      selectedLocCategory,
      selectedScenario,
      selectedDescription,
      selectedBoxId,
      selectedTickerText
    );

    this.updateCheckAll();
  }

  updateRowsWithFilters(
    aptCode,
    locCategory,
    scenario,
    description,
    boxId,
    tickerText
  ) {
    const rows = this.element.querySelectorAll("tbody tr");

    const descriptionRegex = description ? new RegExp(description, "i") : null;

    const boxIdRegex = boxId ? new RegExp(boxId, "i") : null;

    const tickerTextRegex = tickerText ? new RegExp(tickerText, "i") : null;

    rows.forEach((row) => {
      const rowAptCode = row.getAttribute("data-apt-code");
      const categoryCell = row.querySelector("td.loc_category") as HTMLElement;
      const categories = categoryCell
        ? categoryCell.innerText.split("\n").map((cat) => cat.trim())
        : [];
      const rowScenario = row.getAttribute("data-scenario");
      const rowDescription = row.getAttribute("data-description") || "";
      const rowBoxId = row.getAttribute("data-box-id") || "";
      const rowTickerText = row.getAttribute("data-tickertext") || "";

      const aptCodeMatches =
        !aptCode || aptCode === "" || rowAptCode === aptCode;
      const locCategoryMatches =
        !locCategory ||
        locCategory === "" ||
        categories.some((cat) => cat === locCategory);
      const scenarioMatches =
        !scenario || scenario === "" || rowScenario === scenario;

      const descriptionMatches = descriptionRegex
        ? descriptionRegex.test(rowDescription)
        : true;

      const boxIdMatches = boxIdRegex ? boxIdRegex.test(rowBoxId) : true;

      const tickerTextMatches = tickerTextRegex
        ? tickerTextRegex.test(rowTickerText)
        : true;

      if (
        aptCodeMatches &&
        locCategoryMatches &&
        scenarioMatches &&
        descriptionMatches &&
        boxIdMatches &&
        tickerTextMatches
      ) {
        row.classList.remove("hidden");
      } else {
        row.classList.add("hidden");
      }
    });

    this.restoreCheckboxState();
  }

  clearLocalStorage() {
    localStorage.removeItem("selectedCheckboxes");
  }

  saveCheckboxState() {
    const selectedCheckboxes = this.checkboxTargets
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value);

    localStorage.setItem(
      "selectedCheckboxes",
      JSON.stringify(selectedCheckboxes)
    );
  }

  loadCheckboxState() {
    const selectedCheckboxes = JSON.parse(
      localStorage.getItem("selectedCheckboxes") || "[]"
    );

    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = selectedCheckboxes.includes(checkbox.value);
    });
  }

  restoreCheckboxState() {
    const selectedCheckboxes = JSON.parse(
      localStorage.getItem("selectedCheckboxes") || "[]"
    );

    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = selectedCheckboxes.includes(checkbox.value);
    });

    this.updateCheckAll();
  }

  show_modal(e) {
    e.preventDefault();

    const selectedCheckboxes = this.checkboxTargets.filter(
      (checkbox) => checkbox.checked
    );

    const selectedBoxIds = selectedCheckboxes
      .map((checkbox) => checkbox.closest("tr")?.getAttribute("data-box-id"))
      .join(",");

    const scenarioModalElement = document.getElementById(
      "selected-box-ids"
    ) as HTMLSpanElement;

    const standardModalElement = document.getElementById(
      "selected-stelen"
    ) as HTMLSpanElement;

    if (scenarioModalElement) {
      scenarioModalElement.textContent = selectedBoxIds;
    }

    if (standardModalElement) {
      standardModalElement.textContent = selectedBoxIds;
    }

    let selectedScenario;

    selectedScenario = selectedCheckboxes
      .map((checkbox) => checkbox.closest("tr")?.getAttribute("data-scenario"))
      .join(",");

    const isSameScenario = selectedScenario
      .split(",")
      .every((val, i, arr) => val === arr[0]);

    const scenarioSelect = document.getElementById(
      "scenario_id"
    ) as HTMLSelectElement;

    if (isSameScenario) {
      const selectedScenarioValue = selectedScenario.split(",")[0];
      for (let i = 0; i < scenarioSelect.options.length; i++) {
        if (scenarioSelect.options[i].text === selectedScenarioValue) {
          scenarioSelect.value = scenarioSelect.options[i].value;
          this.update_scenario_description(scenarioSelect.value);
          break;
        }
      }
    } else {
      scenarioSelect.value = "";
      this.update_scenario_description("");
    }

    const selectedValidFromArray = selectedCheckboxes
      .map((checkbox) =>
        checkbox.closest("tr")?.getAttribute("data-valid-from")
      )
      .filter(Boolean);

    const isSameValidFrom = selectedValidFromArray.every(
      (val, i, arr) => val === arr[0]
    );

    const validFromElement = document.getElementById(
      "valid_from"
    ) as HTMLInputElement;

    const selectedValidToArray = selectedCheckboxes
      .map((checkbox) => checkbox.closest("tr")?.getAttribute("data-valid-to"))
      .filter(Boolean);

    const isSameValidTo = selectedValidToArray.every(
      (val, i, arr) => val === arr[0]
    );

    const validToElement = document.getElementById(
      "valid_to"
    ) as HTMLInputElement;

    if (
      isSameValidFrom &&
      selectedValidFromArray.length > 0 &&
      selectedValidFromArray[0] &&
      isSameValidTo &&
      selectedValidToArray.length > 0 &&
      selectedValidToArray[0]
    ) {
      // Use the first valid_from value since they are all the same
      validFromElement.value = selectedValidFromArray[0];

      // Use the first valid_to value since they are all the same
      validToElement.value = selectedValidToArray[0];
    } else {
      const now = moment();

      // Format date as 'YYYY-MM-DDTHH:mm'
      validFromElement.value = now.format("YYYY-MM-DDTHH:mm");
      validToElement.value = now.endOf("day").format("YYYY-MM-DDTHH:mm");
    }

    const selectedTickerTextArray = selectedCheckboxes.map((checkbox) =>
      checkbox.closest("tr")?.getAttribute("data-tickertext")
    );

    const isSameTickerText = selectedTickerTextArray.every(
      (val, i, arr) => val === arr[0]
    );

    const tickerTextInputElement = document.querySelector(
      "#ticker_text"
    ) as HTMLInputElement;

    if (isSameTickerText) {
      tickerTextInputElement.value = selectedTickerTextArray[0] ?? "";
    } else {
      tickerTextInputElement.value = "";
    }
  }

  save_modal() {
    const selectedPlayerIds = Array.from(this.checkboxTargets)
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value)
      .join(",");

    const hidden_player_id = document.getElementById(
      "selected-player-ids"
    ) as HTMLInputElement;

    const hidden_standard_player_id = document.getElementById(
      "standard_player_ids"
    ) as HTMLInputElement;

    if (hidden_player_id) {
      hidden_player_id.value = selectedPlayerIds;
    }

    if (hidden_standard_player_id) {
      hidden_standard_player_id.value = selectedPlayerIds;
    }
  }

  change_scenario(event) {
    const selectedScenarioId = event.target.value;
    this.update_scenario_description(selectedScenarioId);
  }

  change_ticker_text(event) {
    const selectedTickerTextId = event.target.value;
    this.update_ticker_text(selectedTickerTextId);
  }

  update_scenario_description(selectedScenarioId) {
    const descriptionElement = document.querySelector(
      "#scenario-description"
    ) as HTMLElement;

    if (!descriptionElement) {
      console.error("Required elements not found.");
      return;
    }

    if (selectedScenarioId) {
      const scenarioElement = document.querySelector(
        `#scenario-${selectedScenarioId}`
      ) as HTMLElement;

      if (scenarioElement) {
        const description = scenarioElement.dataset.description || "";
        descriptionElement.innerHTML = description;
      }

      const scenarioSpots = document.querySelectorAll(".scenario-spots");
      scenarioSpots.forEach((spotElement) => {
        (spotElement as HTMLElement).style.display = "none";
      });

      const selectedSpotElement = document.querySelector(
        `#spots-scenario-${selectedScenarioId}`
      ) as HTMLElement;
      if (selectedSpotElement) {
        selectedSpotElement.style.display = "block";
      }
    } else {
      descriptionElement.innerHTML =
        "Standard Szenario der Box beibehalten und nur Lauftext hinzufügen.";
    }
  }

  update_ticker_text(selectedTickerTextId) {
    const textElement = document.querySelector("#ticker-text") as HTMLElement;

    const tickerTextInputElement = document.querySelector(
      "#ticker_text"
    ) as HTMLInputElement;

    if (!textElement || !tickerTextInputElement) {
      console.error("Required elements not found.");
      return;
    }

    if (selectedTickerTextId) {
      const tickerTextElement = document.querySelector(
        `#ticker_text-${selectedTickerTextId}`
      ) as HTMLElement;

      if (tickerTextElement || selectedTickerTextId !== "0") {
        const text = tickerTextElement.dataset.tickertext || "";
        textElement.innerHTML = "";
        tickerTextInputElement.value = text;
        if (window.currentUser.role === "scenarioUser") {
          tickerTextInputElement.disabled = true;
        } else {
          tickerTextInputElement.disabled = false;
        }
      } else {
        if (selectedTickerTextId === "0") {
          textElement.innerHTML = "";
          tickerTextInputElement.value = "";
          tickerTextInputElement.disabled = true;
        } else {
          console.log("text element not found");
        }
      }
    } else {
      textElement.innerHTML = "Bitte wählen Sie einen Ticker Text aus.";
      tickerTextInputElement.value = "";
    }
  }

  show_player_spots(e) {
    this.list_spots(e);
  }

  show_scenario_spots(e) {
    this.list_spots(e);
  }

  list_spots(e) {
    e.preventDefault();

    const target = e.target as HTMLElement;

    const url = target.dataset.url;

    if (url) {
      const form = document.querySelector(
        "#edit_scenario_1"
      ) as HTMLFormElement;

      if (form) {
        const formData = new URLSearchParams(
          new FormData(form) as any
        ).toString();
        window.location.href = `${url}?${formData}`;
      } else {
        window.location.href = url;
      }
    }
  }
}
